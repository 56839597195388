import { Checkbox, SimpleGrid, Tooltip } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useAuth } from '../../../../hooks/auth';

interface Props {
  set_flag_01: (flag_01: boolean) => void;
  flag_01: boolean;
}

export function ConfigsAvanced({ flag_01, set_flag_01 }: Props) {
  const { user } = useAuth();

  const handleSubmitFalg01 = useCallback(() => {
    try {
      set_flag_01(!flag_01);
    } catch (err) {
      console.log('error', err);
    }
  }, [flag_01, set_flag_01]);
  return (
    <>
      <p>Configurações Avançadas</p>
      <SimpleGrid columns={1} gap={2} mt="0.5rem">
        <Tooltip label="Flag01">
          <Checkbox
            onChange={() => handleSubmitFalg01()}
            defaultChecked={user.flag_1}
          >
            Liberar edição de nome de usuário
          </Checkbox>
        </Tooltip>
        <Checkbox>Flag02</Checkbox>
        <Checkbox>Flag03</Checkbox>
        <Checkbox>
          Visualizar apenas a Filial do usuário em todos os BI
        </Checkbox>
        <Checkbox>Ativar modo editor no BI</Checkbox>
        <Checkbox>Visualizar Filtros no BI</Checkbox>
        <Checkbox>Flag07</Checkbox>
        <Checkbox>Flag08</Checkbox>
        <Checkbox>Flag09</Checkbox>
      </SimpleGrid>
    </>
  );
}
