/* eslint-disable react/jsx-no-bind */
/* eslint-disable prefer-const */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-plusplus */
import { useEffect, useRef, useState, useContext, useCallback } from 'react';
import { useReport } from 'powerbi-report-component';
import { models } from 'powerbi-client';

import {
  Flex,
  Spinner,
  Center,
  ScaleFade,
  Box,
  useDisclosure,
} from '@chakra-ui/react';

import { layoutSettings } from '../../../../utils/stylesOfReportPowerBI';
import { api } from '../../../../services/api';
import reportProps from '../../../../utils/reportProps';
import { DeviceContext } from '../../../../contexts/DeviceContext';
import { apllyToast } from '../../../Global/Toast2.0';
import { PaginationBI } from '../../../_components_0.2/PaginationBI';
import { OptionsViewsBI } from '../../../_components_0.2/OptionsViewsBI';
import { ModalAddNewBookmarkBI } from '../../../_components_0.2/Modal/ModalAddNewBookmarkBI';
import { DrawerNavigationBi } from '../../../_components_0.2/Drawer/DrawerNavigationBi';
import { useAuth } from '../../../../hooks/auth';

interface IDataPages {
  displayName: string;
  isActive: boolean;
  name: string;
  visibility: number;
}
interface Props {
  configId: string;
  embedId: string;
  embedUrl: string;
  reportLoading: string;
  workspaceId: string;
}
interface PropsPowerBI {
  accessToken: string;
  expiry: string;
  reportId: string;
  embedUrl: string;
}

interface BookmarkData {
  bookmark: string;
  name: string;
  access_id: string;
  embedId: string;
  public: boolean;
}

export const ReportBIManagement = ({
  configId,
  embedId,
  embedUrl,
  reportLoading,
  workspaceId,
}: Props) => {
  const { onClose, isOpen, onOpen } = useDisclosure();
  const {
    onClose: onCloseDrawer,
    isOpen: isOpenDrawer,
    onOpen: onOpenDrawer,
  } = useDisclosure();

  const { user } = useAuth();
  const reportRef = useRef(null);
  const [report, setEmbed] = useReport();
  const { window } = useContext(DeviceContext);
  const [loadingRendered, setLoadingRendered] = useState(true);
  const [viewsPagesReportBi, setViewPagesReportBI] = useState<IDataPages[]>([]);
  const [bookmark, setbBookmark] = useState('');
  const [bookmarkDataUpdated, setbBookmarkDataUpdated] = useState();

  const [bookmarkData, setbBookmarkData] = useState<BookmarkData[]>([]);

  useEffect(() => {
    async function loadTokenBI(): Promise<void> {
      try {
        await api
          .get<PropsPowerBI>(
            `/get-embed-token?reportId=${reportLoading}&workspaceId=${workspaceId}&configId=${configId}`,
          )
          .then(response => {
            const formated = {
              embedId,
              embedUrl,
              window,
              accessToken: response.data.accessToken,
              activeEditorBI: user.flag_5,
              activeViewFiltersBI: user.flag_6,
            };
            console.log('executou api');
            const { initialReportProps } = reportProps(formated);

            setEmbed(reportRef, initialReportProps as any);
          });
      } catch (err) {
        console.log('problemas', err);
        apllyToast(
          'error',
          'Problemas ao carregar relatório, consulte o administrador.',
        );
        setLoadingRendered(false);
      }
    }
    loadTokenBI();
  }, [configId, embedId, embedUrl, reportLoading, window, workspaceId]);

  useEffect(() => {
    try {
      api
        .get(`/power-bi-views/show?access_id=${user.id}&embedId=${embedId}`)
        .then(response => {
          setbBookmarkData(response.data);
        });
    } catch (err) {
      console.log('error', err);
    }
  }, [bookmarkDataUpdated, embedId]);

  async function handle() {
    try {
      if (report) {
        const pages = await report.getPages();

        setViewPagesReportBI(pages);
        if (!user.flag_4) {
          await handleSetFilters(user.access_of_sector.branch);
        }
        setLoadingRendered(false);
        console.log('relatório renderizado solto');
      }
    } catch (e) {
      console.log('error', e);
    }
  }

  async function handleCapturebookmark() {
    if (report) {
      const capturedBookmark = await report.bookmarksManager.capture({
        personalizeVisuals: true,
      });

      const bookmarke = {
        name: `bookmark_${report.bookmarksManager.bookmarkCounter}`,
        state: capturedBookmark.state,
      };

      setbBookmark(bookmarke.state);
      onOpen();
    }
  }

  if (report) {
    report.off('loaded');
    report.on('loaded', async function () {
      // if (user.flag_4) {
      //   try {
      //     const filter = {
      //       $schema: 'http://powerbi.com/product/schema#basic',
      //       target: {
      //         table: 'filial',
      //         column: 'filial',
      //       },
      //       operator: 'In',
      //       values: [1],
      //     };

      //     console.log('Aplicando filtro automaticamente...');
      //     await report.updateFilters(models.FiltersOperations.Add, [filter]);
      //     console.log('Filtro aplicado com sucesso.');

      //     // Verificar filtros atuais
      //     const currentFilters = await report.getFilters();
      //     console.log('Filtros atuais:', currentFilters);
      //   } catch (error) {
      //     console.error('Erro ao aplicar filtro:', error);
      //   }
      // }
      await handle();
    });
  }

  async function handleSetBookmark(state: string) {
    try {
      console.log('setou a view');
      await report.bookmarksManager.applyState(state);
    } catch (err) {
      console.log('error', err);
    }
  }

  const handlesSetStateForBookmark = useCallback(dataBookmark => {
    try {
      api.post('/power-bi-views', dataBookmark).then(response => {
        setbBookmarkDataUpdated(response.data);
      });
    } catch (err) {
      console.log('error', err);
    }
  }, []);

  async function handleFullScreen() {
    if (report) {
      await report.updateSettings({ background: 0 });
      report.fullscreen();
    }
  }
  async function handlePrint() {
    if (report) {
      await report.print();
    }
  }

  async function handleSetFilters(branch: string) {
    if (report) {
      const filter = {
        $schema: 'http://powerbi.com/product/schema#basic',
        target: {
          table: 'filial',
          column: 'filial',
        },
        operator: 'In',
        values: [Number(branch)],
      };

      await report.updateFilters(models.FiltersOperations.Add, [filter]);

      const currentFilters = await report.getFilters();
      console.log('Filtros atuais:', currentFilters);
    }
  }

  return (
    <>
      <Center>{loadingRendered ? <Spinner /> : ''}</Center>
      <ScaleFade initialScale={0.9} in>
        <Box
          display={loadingRendered ? 'none' : ''}
          style={layoutSettings()}
          ref={reportRef}
          w="100%"
        />
        <Flex
          position="fixed"
          overflowX="auto"
          whiteSpace="nowrap"
          width="100%"
          bottom="50%"
          left="0"
          display={loadingRendered ? 'none' : ''}
        >
          <OptionsViewsBI
            handleCapturebookmark={() => handleCapturebookmark()}
            onOpen={onOpenDrawer}
            handleFullScreen={handleFullScreen}
            handlePrint={handlePrint}
          />
          <ModalAddNewBookmarkBI
            handlesSetStateForBookmark={handlesSetStateForBookmark}
            embedId={embedId}
            bookmark={bookmark}
            isOpen={isOpen}
            onClose={onClose}
          />
        </Flex>

        <Flex
          position="fixed"
          overflowX="auto"
          whiteSpace="nowrap"
          width="100%"
          bottom="1.5"
          left="0"
        >
          <PaginationBI dataPagesBi={viewsPagesReportBi} report={report} />
        </Flex>
        <DrawerNavigationBi
          onClose={onCloseDrawer}
          isOpen={isOpenDrawer}
          bookmarkData={bookmarkData}
          handleSetBookmark={handleSetBookmark}
        />
      </ScaleFade>
    </>
  );
};
