/* eslint-disable no-nested-ternary */
/* eslint-disable no-constant-condition */
/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from 'react';

import { Link } from 'react-router-dom';
import {
  Box,
  Center,
  SimpleGrid,
  useBreakpointValue,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  ScaleFade,
} from '@chakra-ui/react';
import { useAuth } from '../../../hooks/auth';

import { ContainerCard, Content } from './styles';

import destin from '../../../assets/destin.svg';
import pie from '../../../assets/pie.svg';
import os from '../../../assets/os.svg';
import fluxo from '../../../assets/fluxo.svg';
import adm from '../../../assets/adm.svg';
import folders from '../../../assets/folders.png';
import pic from '../../../assets/pic.svg';
import growth from '../../../assets/growth.png';
import lucro from '../../../assets/lucro.png';
import pacientes from '../../../assets/pacientes.png';
// import book from '../../../assets/book.png';
import { SetToggleThemeContext } from '../../../contexts/SetToggleThemeContext';
import { useCan } from '../../../hooks/useCan';
import { api } from '../../../services/api';

const MenuList: React.FC = () => {
  const [userDataUpdate, setUserDataUpdate] = useState(false);
  const [userViewPerformanceEvaluation, setUserViewPerformanceEvaluation] =
    useState(false);
  const isWideVersion = useBreakpointValue({
    base: false,
    lg: true,
  });

  const { user } = useAuth();
  const { toggleTheme } = useContext(SetToggleThemeContext);

  const userCanSeeFileManager = useCan({
    permissions: ['file_manager'],
  });
  const userCanSeeStrategicPlanning = useCan({
    permissions: ['strategic_planning'],
  });
  const userCanSeePIC = useCan({
    permissions: ['pic_access'],
  });
  const userCanSeeOrderServiceData = useCan({
    permissions: ['user_orderServices_data'],
  });
  const userCanViewPerformanceEvaluation = useCan({
    permissions: ['user_wiew_performance_evaluation'],
  });

  useEffect(() => {
    try {
      api
        .get(
          `/hierarchies/filter?take=999&page=1&leader=${user.nickname}&year=2024`,
        )
        .then(() => {
          if (userCanViewPerformanceEvaluation) {
            setUserViewPerformanceEvaluation(true);
          }
        });
    } catch (error) {
      console.log('error', error);
    }
  }, [user.nickname, userCanViewPerformanceEvaluation]);

  useEffect(() => {
    if (!user.senior_id) {
      setUserDataUpdate(true);
    }
  }, [user.senior_id]);

  // utilizado para converter os dados da hierarquia do senior em - 2024
  // useEffect(() => {
  //   const dataformated1 = data.map(item => {
  //     const primeira = {
  //       username: item.username,
  //       subordinates: item.subordinates.map(dt => {
  //         return { username: dt.username };
  //       }),
  //     };
  //     return primeira;
  //   });

  //   const dataformated2 = data.map(item => {
  //     const segunda = {
  //       username: item.subordinates.map(dt => dt.username)[0],
  //       subordinates: item.subordinates.map(dt => {
  //         const a = dt.subordinates.find(dta => {
  //           return {
  //             username: dta.username,
  //           };
  //         });
  //         return a;
  //       }),
  //     };
  //     return segunda;
  //   });

  //   const dataformated3 = data.map(item => {
  //     const terceira = item.subordinates.map(dd => {
  //       const terceiraq = dd.subordinates.map(qq => {
  //         // console.log(dd);
  //         const { username } = qq;
  //         const subordinates =
  //           qq.subordinates === undefined
  //             ? {}
  //             : qq.subordinates.map(ted => {
  //                 return { username: ted.username };
  //               });
  //         const terceiraqq = {
  //           username,
  //           subordinates,
  //         };
  //         return terceiraqq;
  //       });
  //       return terceiraq;
  //     });

  //     return terceira[0];
  //   });

  //   const dataformated4 = data.map(item => {
  //     const terceira = item.subordinates.map(dd => {
  //       const terceiraq = dd.subordinates.map(ll => {
  //         const terceiraa =
  //           ll.subordinates === undefined
  //             ? {}
  //             : ll.subordinates
  //                 .map(hh => {
  //                   // console.log(hh);
  //                   const { username } = hh;
  //                   const subordinates =
  //                     'subordinates' in hh && hh.subordinates === undefined
  //                       ? undefined
  //                       : 'subordinates' in hh && Array.isArray(hh.subordinates)
  //                       ? hh.subordinates.map(ted => ({
  //                           username: ted.username,
  //                         }))
  //                       : 'subordinates' in hh &&
  //                         typeof hh.subordinates === 'object'
  //                       ? Array.isArray(hh.subordinates)
  //                         ? hh.subordinates.map(sub => sub.username)
  //                         : undefined
  //                       : undefined;
  //                   const terceiraqq = {
  //                     username,
  //                     subordinates,
  //                   };
  //                   return terceiraqq;
  //                 })
  //                 .filter(nh => nh.subordinates !== undefined);
  //         return terceiraa;
  //       });
  //       return terceiraq;
  //     });

  //     return terceira[0];
  //   });

  //   const dataformated5 = data.map(item => {
  //     const terceira = item.subordinates.map(dd => {
  //       const terceiraq = dd.subordinates.map(ll => {
  //         const terceiraa =
  //           ll.subordinates === undefined
  //             ? {}
  //             : ll.subordinates.map(hh => {
  //                 // console.log(hh);
  //                 if ('subordinates' in hh && hh.subordinates) {
  //                   const formated = {
  //                     username: hh.username,
  //                     subordinates: Array.isArray(hh.subordinates)
  //                       ? hh.subordinates.map(sub => {
  //                           return {
  //                             username: sub.username,
  //                           };
  //                         })
  //                       : hh.subordinates
  //                       ? hh.subordinates.username
  //                       : (hh as any).subordinates,
  //                   };

  //                   return formated;
  //                 }
  //                 return {};

  //                 // const quinto =
  //                 //   hh.subordinates === undefined
  //                 //     ? undefined
  //                 //     : Array.isArray(hh.subordinates)
  //                 //     ? hh.subordinates.map(gg => {
  //                 //         const username = gg.name;
  //                 //         // const subordinates =
  //                 //         //   gg.subordinates === undefined
  //                 //         //     ? undefined
  //                 //         //     : Array.isArray(gg.subordinates)
  //                 //         //     ? gg.subordinates.map(ted => ({
  //                 //         //         username: ted.name,
  //                 //         //       }))
  //                 //         //     : typeof gg.subordinates === 'object'
  //                 //         //     ? gg.subordinates
  //                 //         //     : undefined;
  //                 //         const terceiraqq = {
  //                 //           username,
  //                 //           subordinates: {},
  //                 //         };
  //                 //         return terceiraqq;
  //                 //       })
  //                 //     : undefined;
  //                 // return quinto;
  //                 // console.log('formatrados', formated);
  //                 // return formated;
  //               });

  //         return (
  //           Array.isArray(terceiraa) && terceiraa.filter(nh => nh.subordinates)
  //         );
  //       });
  //       return terceiraq;
  //     });

  //     return terceira[0];
  //   });

  //   const f = dataformated4
  //     .filter(e => {
  //       return Array.isArray(e) && e.length >= 0;
  //     })[0]
  //     .filter(r => {
  //       return Array.isArray(r) && r[0];
  //     });

  //   const l = dataformated5
  //     .filter(e => {
  //       return Array.isArray(e) && e.length >= 0;
  //     })[0]
  //     .filter(r => {
  //       return Array.isArray(r) && r[0];
  //     });

  //   const mergedData = [
  //     ...dataformated1,
  //     ...dataformated2,
  //     ...dataformated3[0],
  //     ...(Array.isArray(f[0]) ? f[0] : []),
  //     ...(Array.isArray(l[1]) ? l[1] : []),
  //     ...(Array.isArray(l[2]) ? l[2] : []),
  //   ];
  //   console.log('dataformated5', l);
  //   console.log('dataformated1', {
  //     mergedData,
  //   });
  // }, []);

  return (
    <>
      {userDataUpdate && (
        <ScaleFade initialScale={0.9} in>
          <Alert status="error">
            <AlertIcon />
            <AlertTitle>Seus dados estão desatualizados!</AlertTitle>
            <AlertDescription>
              <Link to="/profile">Saber mais</Link>
            </AlertDescription>
          </Alert>
        </ScaleFade>
      )}

      <ContainerCard theme={toggleTheme}>
        <Center mt="-40">
          <SimpleGrid
            flex="1"
            columns={[2, null, 4]}
            // columns={{ sm: 2, md: 4 }}
            spacing={5}
            // minChildWidth={['370px', '400', '420px']}
            alignItems="flex-start"
            mt={isWideVersion ? '0' : '10rem'}
          >
            <Box m="5px">
              <Link to="/report-income-statement">
                <Content>
                  <img
                    src={lucro}
                    className="lucro"
                    alt="Participação nos lucros"
                  />
                  <strong>Gestão a vista</strong>
                </Content>
              </Link>
            </Box>
            <Box m="5px">
              <Link to="/miles/dashboard">
                <Content>
                  <img src={destin} alt="imagem de destino" />
                  <strong>Controle de frota</strong>
                </Content>
              </Link>
            </Box>

            <Box m="5px">
              {/* <Link to="/management-ppr/dashboard"> */}
              <Link to="/bi-management/dashboard">
                <Content>
                  <img src={pie} alt="imagem de grafico pizza" />
                  {/* <strong>Gerenciamento PPR</strong> */}
                  <strong>Inteligência de negócios</strong>
                </Content>
              </Link>
            </Box>

            {/* <div className={user.tag === 'admin' ? '' : 'disabled'}> */}
            <Box m="5px">
              <Link
                to={
                  userCanSeeOrderServiceData
                    ? '/service-orders-ti/painel'
                    : '/service-orders-ti/user'
                }
              >
                <Content>
                  <img src={os} alt="imagem de ordem de serviço" />
                  <strong>Ordens de serviço</strong>
                </Content>
              </Link>
            </Box>
            {/* </div> */}

            {/* <Box className={user.tag === 'admin' ? '' : 'disabled'} m="5px"> */}
            <Box m="5px">
              <Link to="/rules">
                <Content>
                  <img src={fluxo} alt="imagem de fluxo grama" />
                  <strong>Normas e manuais</strong>
                </Content>
              </Link>
            </Box>

            {userCanSeeFileManager && (
              <Box m="5px">
                <Link to="/file-management">
                  <Content>
                    <img src={folders} alt="imagem de fluxo grama" />
                    <strong>Gerenciador de Arquivos</strong>
                  </Content>
                </Link>
              </Box>
            )}
            {userCanSeePIC && (
              <Box m="5px">
                <Link to="/pic">
                  <Content>
                    <img src={pic} alt="imagem de fluxo grama" />
                    <strong>Plano de invest. Cooasgo</strong>
                  </Content>
                </Link>
              </Box>
            )}

            {userCanSeeStrategicPlanning && (
              <Box m="5px">
                <Link to="/strategic-planning">
                  <Content>
                    <img
                      src={growth}
                      alt="imagem de planejamento estratégico"
                    />
                    <strong>Planejamento estratégico</strong>
                  </Content>
                </Link>
              </Box>
            )}
            {userViewPerformanceEvaluation && (
              <Box m="5px">
                <Link to="/performance-evaluation/home">
                  <Content>
                    <img
                      src={pacientes}
                      className="pacientes"
                      alt="imagem de avaliação de desempenho"
                    />
                    <strong>Avaliação de desempenho</strong>
                  </Content>
                </Link>
              </Box>
            )}

            {/* <Box m="5px">
            <Link to="/schedule">
              <Content>
                <img src={book} alt="imagem de agenda" />
                <strong>Agenda Cooasgo</strong>
              </Content>
            </Link>
          </Box> */}

            {/* </Box> */}
            <Box className={user.tag === 'admin' ? '' : 'disabled'} m="5px">
              <Link to="/administrator/employers">
                <Content>
                  <img src={adm} alt="imagem de administrador" />
                  <strong>Administrador</strong>
                </Content>
              </Link>
            </Box>
          </SimpleGrid>
        </Center>
      </ContainerCard>
    </>
  );
};

export default MenuList;
